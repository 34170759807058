import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';  // Import environment to check production status

@Injectable({
  providedIn: 'root'  // This makes it available throughout your app without needing to import in every component
})
export class LoggingService {
  // Enable or disable logging based on environment
  private isLoggingEnabled: boolean = !environment.production;

  // Method to log different levels of messages
  log(message: any, level: 'info' | 'warn' | 'error' = 'info'): void {
    if (!this.isLoggingEnabled) return;  // Do not log in production

    const timestamp = new Date().toISOString();
    switch (level) {
      case 'info':
        console.log(`[INFO] ${timestamp}:`, message);
        break;
      case 'warn':
        console.warn(`[WARN] ${timestamp}:`, message);
        break;
      case 'error':
        console.error(`[ERROR] ${timestamp}:`, message);
        break;
      default:
        console.log(`[INFO] ${timestamp}:`, message);
    }
  }
}
