import { CanActivateFn } from '@angular/router';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private storageService: StorageService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Check if the user_setting token exists in storage
    return this.storageService.get('user').then((token) => {
      if (token) {
        console.log(token)
        // If the token exists, allow access to the route (e.g., home)
        return true;
      }
      // If no token, redirect the user to login
      this.router.navigateByUrl('/login');
      return false; // Prevent navigation to the home route
    });
  }
}
