import { Component } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './services/storage.service';
import { CommonService } from './services/common.service';
import { LoggingService } from './services/logging.service';
import { HeaderPage } from './header/header.page';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet, HeaderPage],
})
export class AppComponent {
  language: any = 'en';
  constructor(private commonService: CommonService, private storageService: StorageService, private logginService: LoggingService, private router: Router) {
    this.initializeTranslation();
  }

  async initializeTranslation() {
    this.logginService.log('Translation initialization started');
    this.logginService.log(this.storageService.getDeviceInfo())
    this.logginService.log(this.storageService.getDeviceId() + "DEVICEIDDDDD", 'info')
    this.language = await this.storageService.get('lang');
    const token = await this.storageService.get('auth-token');
    console.log(token)
    // if (!token) {
    //   this.showHeader = true;
    //   const currentUrl = this.router.url;
    //   if (currentUrl.startsWith('/login-otp?')) {
    //     this.router.navigateByUrl('/login');
    //   }
    // }

    console.log(this.language)
    if (this.language == null) {
      this.language = 'en'
    }
    this.commonService.initializeTranslation(this.language);

  }
}
