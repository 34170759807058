import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from './logging.service';

import { AlertController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private showSuccessMessage = new BehaviorSubject<boolean>(false);
  currentMessage = this.showSuccessMessage.asObservable();

  private quoteDetail = new BehaviorSubject<any>({});
  quoteDetailInfo = this.quoteDetail.asObservable();

  private routeChange = new BehaviorSubject<any>({});
  routeChangeInfo = this.routeChange.asObservable();

  private firstLogin = new BehaviorSubject<boolean>(false);
  firstLoginInfo = this.firstLogin.asObservable();

  private changeLanguage = new BehaviorSubject<any>('en');
  changeLanguageInfo = this.changeLanguage.asObservable();

  private updateAdditional = new BehaviorSubject<boolean>(false);
  updateAdditionalInfo = this.updateAdditional.asObservable();


  changeMessage(status: boolean) {
    this.showSuccessMessage.next(status);
  }

  setQuoteDetail(data: any) {
    this.quoteDetail.next(data);
  }
  routeChangeCall(data: any) {
    this.routeChange.next(data);
  }
  firstLoginCall(data: any) {
    this.firstLogin.next(data);
  }
  changeLanguageCall(data: any) {
    this.changeLanguage.next(data);
  }
  updateAdditionalCall(data: any) {
    this.updateAdditional.next(data);
  }

  constructor(private alertController: AlertController, private storageService: StorageService, private translate: TranslateService, private loggingService: LoggingService, private toastController: ToastController) { }

  initializeTranslation(language: string) {
    this.translate.setDefaultLang(language); // Set default language
    this.translate.use(language).subscribe(() => {
      this.loggingService.log('Language loaded successfully');
      this.storageService.set('lang', language);
      this.changeLanguageCall(language)
      return language;
    }, (error) => {
      this.loggingService.log('Error loading language:' + error, 'error');
    });
  }

  async confirm(message: string): Promise<boolean> {
    const alert = await this.alertController.create({
      header: 'Confirm',
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            return false; // User canceled
          },
        },
        {
          text: 'Confirm',
          handler: () => {
            return true; // User confirmed
          },
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role !== 'cancel'; // Return true if confirmed, false if canceled
  }

  async showSuccessToast(message: string) {

    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: 'top',
      color: 'success'
    });
    this.currentMessage.subscribe(message => {
      console.log(message)
      if (message) {
        toast.present();


      }
    });

  }

  async showErrorToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'top',
      color: 'danger',
      duration:25000,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
            toast.dismiss();
          },
        },
      ],
    });
    if (message) {
      toast.present();
      this.changeMessage(false);
    }
  }


}
