import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() { }

  // Set an item in storage
  async set(key: string, value: string) {
    await Preferences.set({
      key: key,
      value: value
    });
  }

  // Get an item from storage
  async get(key: string, additionalKey?: string) {
    const { value } = await Preferences.get({ key: key });
    if (additionalKey && value) {
      return JSON.parse(value)[additionalKey];
    }
    return value;
  }

  // Remove an item from storage
  async remove(key: string) {
    await Preferences.remove({ key: key });
  }

  // Clear all storage
  async clear() {
    await Preferences.clear();
  }

  // Get device information
  async getDeviceInfo() {
    const info = await Device.getInfo();
    return info;
  }

  // Get device ID
  async getDeviceId() {
    const info = await Device.getId();
    return info;
  }
}
