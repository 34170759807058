import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { Preferences } from '@capacitor/preferences';  // Import Capacitor Storage
import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { provideHttpClient, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateService, TranslateLoader } from '@ngx-translate/core'; // Import Translate-related services
import { TranslateHttpLoader } from '@ngx-translate/http-loader'; // Import TranslateHttpLoader
import { importProvidersFrom } from '@angular/core';
import { InjectionToken } from '@angular/core';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

// Function to create HttpLoaderFactory
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

defineCustomElements(window);
if (environment.production) {
  enableProdMode();
}

// Define an interface for the Storage service
export interface StorageService {
  set(key: string, value: string): Promise<void>;
  get(key: string): Promise<string | null>;
  remove(key: string): Promise<void>;
  clear(): Promise<void>;
}

// Implement the StorageService using Capacitor Storage
class CapacitorStorageService implements StorageService {
  async set(key: string, value: string, group: string = "OrnSys"): Promise<void> {
    await Preferences.set({ key, value });
  }

  async get(key: string): Promise<string | null> {
    const { value } = await Preferences.get({ key });
    return value;
  }

  async remove(key: string): Promise<void> {
    await Preferences.remove({ key });
  }

  async clear(): Promise<void> {
    await Preferences.clear();
  }
}

// Create an instance of the CapacitorStorageService
const storageService = new CapacitorStorageService();

// Create an InjectionToken for the StorageService
export const STORAGE_SERVICE_TOKEN = new InjectionToken<StorageService>('StorageService');

// Configure the router with withRouterConfig
const routerConfig = withRouterConfig({
  onSameUrlNavigation: 'reload',
});

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideRouter(routes, withComponentInputBinding(), routerConfig), // Use the router configuration here
    provideHttpClient(),
    {
      provide: STORAGE_SERVICE_TOKEN,  // Use your custom storage service
      useValue: storageService,
    },
    TranslateService,
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient], // Ensure HttpClient is passed as a dependency
        }
      })
    ),
  ],
});
