import { Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'login', // <-- Add the :id parameter
    loadComponent: () =>
      import('./login/login.page').then((m) => m.LoginPage),

  },
  {
    path: 'login-otp',
    loadComponent: () => import('./login-otp/login-otp.page').then(m => m.LoginOTPPage),
    data: { phoneNo: null } // Add a data property to hold the phoneNo
  },
  {
    path: 'outlet',
    // canActivate: [AuthGuard],
    loadComponent: () => import('./features/outlet/outlet.page').then(m => m.OutletPage),
    children: [
      {
        path: '',
        loadComponent: () => import('./features/outlet/outlet-list/outlet-list.page').then(m => m.OutletListPage),
        pathMatch: 'full',
      },
      {
        path: 'add',
        loadComponent: () => import('./features/outlet/outlet-add-edit/outlet-add-edit.page').then(m => m.OutletAddEditPage),
        pathMatch: 'full',
      },
      {
        path: 'edit/:id',
        loadComponent: () => import('./features/outlet/outlet-add-edit/outlet-add-edit.page').then(m => m.OutletAddEditPage),
        pathMatch: 'full',
      },
      {
        path: 'users',
        loadComponent: () => import('./features/outlet/outlet-user/outlet-user.page').then( m => m.OutletUserPage),
        children: [
          {
            path: ':userid',
            loadComponent: () => import('./features/outlet/outlet-user/outlet-user-list/outlet-user-list.page').then( m => m.OutletUserListPage)
          },
          {
            path: 'add/:userid',
            loadComponent: () => import('./features/outlet/outlet-user/outlet-user-add-edit/outlet-user-add-edit.page').then( m => m.OutletUserAddEditPage)
          },
          {
            path: 'edit/:userid/:id',
            loadComponent: () => import('./features/outlet/outlet-user/outlet-user-add-edit/outlet-user-add-edit.page').then( m => m.OutletUserAddEditPage)
          },
        ]
      }
      // Optionally, add more child routes for viewing, etc.
    ],
  },
  {
    path: '',
    loadComponent: () => import('./features/features.page').then(m => m.FeaturesPage),
    canActivate: [AuthGuard],
    children: [
      {
        path: 'term',
        loadComponent: () => import('./features/term/term.page').then( m => m.TermPage),
        canActivate: [AuthGuard]
      },
      {
        path: 'home',
        loadComponent: () => import('./features/home/home.page').then(m => m.HomePage),
        canActivate: [AuthGuard]
      },
      {
        path: 'profile',
        loadComponent: () => import('./features/profile/profile.page').then(m => m.ProfilePage),

      },
      {
        path: 'policies',
        loadComponent: () => import('./features/policies/policies.page').then(m => m.PoliciesPage)
      },
      {
        path: 'product',
        loadComponent: () => import('./features/product/product.page').then(m => m.ProductPage)
      }
    ]
  },
  {
    path: 'quotation',
    canActivate: [AuthGuard],
    loadComponent: () => import('./features/quotation/quotation.page').then(m => m.QuotationPage),
    children: [
      {

        path: 'mi',
        children: [
          {
            path: '',
            loadComponent: () => import('./features/quotation/mi/mi.page').then(m => m.MiPage),
            pathMatch: 'full'
          },
          {
            path: 'quote-list/:id',
            loadComponent: () => import('./features/quotation/mi/quote-list/quote-list.page').then(m => m.QuoteListPage)
          },
          {
            path: 'get-quote',
            loadComponent: () => import('./features/quotation/mi/get-quote/get-quote.page').then(m => m.GetQuotePage)
          },
          {
            path: 'get-cover/:quote',
            loadComponent: () => import('./features/quotation/mi/get-cover/get-cover.page').then(m => m.GetCoverPage)
          },
          {
            path: 'additional-info/:quote',
            loadComponent: () => import('./features/quotation/mi/additional-info/additional-info.page').then(m => m.AdditionalInfoPage)
          },
          {
            path: 'document/:quote',
            loadComponent: () => import('./features/quotation/mi/document/document.page').then(m => m.DocumentPage)
          },

        ]
      },
      {
        path: 'pai',
        children: [
          {
            path: '',
            loadComponent: () => import('./features/quotation/pai/pai.page').then( m => m.PaiPage)
          },
          {
            path: 'get-cover',
            loadComponent: () => import('./features/quotation/pai/get-cover/get-cover.page').then( m => m.GetCoverPage)
          },
          {
            path: 'additional-page/:quote',
            loadComponent: () => import('./features/quotation/pai/additional-page/additional-page.page').then( m => m.AdditionalPagePage)
          }
        ]

      },
    ]
  },
  {
    path: 'stepper',
    loadComponent: () => import('./features/quotation/mi/stepper/stepper.page').then(m => m.StepperPage)
  },
  {
    path: 'setting',
    loadComponent: () => import('./features/profile/setting/setting.page').then(m => m.SettingPage)
  },
  {
    path: 'commission',
    loadComponent: () => import('./features/profile/commission/commission.page').then(m => m.CommissionPage)
  },
  {
    path: 'wallet',
    loadComponent: () => import('./features/profile/wallet/wallet.page').then(m => m.WalletPage)
  },
  {
    path: 'payments/:quote/:payment-type',
    loadComponent: () => import('./features/payments/payments.page').then(m => m.PaymentsPage)
  },
  {
    path: 'pagination',
    loadComponent: () => import('./features/pagination/pagination.page').then(m => m.PaginationPage)
  },
  {
    path: 'success/:quote',
    loadComponent: () => import('./features/success/success.page').then(m => m.SuccessPage)
  },

  {
    path: 'payment-type/:quote',
    loadComponent: () => import('./features/payment-type/payment-type.page').then( m => m.PaymentTypePage)
  },
  {
    path: 'report',
    loadComponent: () => import('./features/report/report.page').then( m => m.ReportPage)
  },
  {
    path: 'payments-list',
    loadComponent: () => import('./features/payments-list/payments-list.page').then( m => m.PaymentsListPage)
  },
  {
    path: 'payments-add/:id',
    loadComponent: () => import('./features/payments-list/paymets-add/paymets-add.page').then( m => m.PaymetsAddPage)
  },
  {
    path: 'download-setting/:quote',
    loadComponent: () => import('./features/download-setting/download-setting.page').then( m => m.DownloadSettingPage)
  },




















];
